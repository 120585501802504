import React from "react"

const Contacto = ({ taller }) => {
    const [showData, setShowData] = React.useState(false);

    return <section id="contacto" className="contact">
      <div className="container">
        <div className="top">
          <h2 className="title">Contacto</h2>
        </div>

        <form id="formContact" className="form" action="contacto.php" method="post" encType="multipart/form-data">
          <div className="left">
            <div className="formGroup">
              <input type="text" id="iName" name="iName" className="formControl" required />
              <label htmlFor="iName" className="placeholder">Nombre<span className="req">*</span></label>
            </div>
            <div className="formGroup">
              <input type="text" id="iEmail" name="iEmail" className="formControl" required />
              <label htmlFor="iEmail" className="placeholder">Email<span className="req">*</span></label>
            </div>
            <div className="formGroup">
              <input type="tel" id="iPhone" name="iPhone" className="formControl" maxLength = "20" />
              <label htmlFor="iPhone" className="placeholder">Teléfono<span className="req">*</span></label>
            </div>
          </div>

          <div className="right">
            <div className="formGroup">
              <textarea id="iComentary" maxLength="500" placeholder="Cuéntanos lo que necesitas..." className="formControl"></textarea>
            </div>
          </div>

          <div className="bottom">
            <div className="formGroup">
              <div className="fgCB">
                <input type="checkbox" id="cb01" name="cb01" data-error="errCB1" />
                <label htmlFor="cb01" className="lblCB"><span>Acepto los  <a href="/" className="link linkModal" data-modal data-rel="#data-modal" >términos y condiciones</a></span></label>
                <div className="errorTxt">
                  <span id="errCB1"></span>
                </div>
              </div>

              <div className="fgCB">
                <input type="checkbox" id="cb02" name="cb02" data-error="errCB2" />
                <label htmlFor="cb02" className="lblCB"><span>Autorizo a Bosch Car Service a usar mi email para mandarme noticias y promociones.</span></label>
                <div className="errorTxt">
                  <span id="errCB2"></span>
                </div>
              </div>
            </div>

            <div className="formGroup fgLegend">
              <span className="legend">* Campo obligatorio</span>
              <button id="btnSend" type="button" className="btn btnType2 big btnSend">Enviar mensaje</button>
            </div>
          </div>
        </form>
      </div>
      <div className="container">
        <a style={{ color: "#000", textDecoration: "underline" }} onClick={ (event) => {  event.preventDefault(); setShowData(!showData) } } href="/" className="show-data-info">
          {showData?"-":"+"} Información básica sobre protección de datos</a>
        <table style={{ marginTop: "12px", display: showData?"block":"none" }} className="hide" id="data-info">          

          <tr><td>Responsable</td><td>{taller.title.replace("<br/>", "")}</td></tr>

          <tr><td>Finalidad </td><td>Sus datos son recabados para atender sus consultas y peticiones, envío de ofertas y publicidad relacionados siempre con nuestra actividad.</td></tr>

          <tr><td>Legitimación </td><td>Consentimiento del interesado.</td></tr>

          <tr><td>Destinatarios </td><td>Sus datos no se cederán a terceros salvo obligación legal.</td></tr>

          <tr><td>Derechos </td><td>Tiene derecho a solicitar el acceso, rectificación, supresión, limitación del tratamiento, oposición o derecho a la portabilidad de sus datos personales.</td></tr>

          <tr><td>Información adicional</td><td>Puede consultar la información ampliada y detallada sobre protección de datos en el anexo 'Información adicional' o en   
          nuestra <a style={{ color: "#000", textDecoration: "underline" }} href="/" className="link linkModal" data-modal data-rel="#privacy-modal">página web</a>.</td></tr>
          <tr><td colspan="2">{taller.title.replace("<br/>", "")}, como responsable del tratamiento, le informa que los datos proporcionados voluntariamente por Ud. a través del formulario 
          disponible en la página web de la empresa, es con la finalidad de atender sus consultas y ofrecer los servicios solicitados. La base jurídica para el tratamiento
           es el consentimiento del interesado. Sus datos no se cederán a terceros salvo obligación legal. Cualquier persona tiene derecho a solicitar el acceso, 
           rectificación, supresión, limitación del tratamiento, oposición o derecho a la portabilidad de sus datos personales, escribiéndonos a 
           nuestra dirección {taller.contact.address}; {taller.contact.province}, o enviando un correo electrónico a <a style={{ color: "#000", textDecoration:"underline"}} href={"mailto:"+taller.contact.email}>{taller.contact.email}</a>, 
           indicando el derecho que desea ejercer. Más información <a style={{ color: "#000", textDecoration: "underline" }} href="/" className="link linkModal" data-modal data-rel="#data-modal">aquí</a></td></tr>
        </table>
      </div>
    </section>
}
export default Contacto