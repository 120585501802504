import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
//import Promo1 from '../images/promociones/promo1.jpg'
//import Tip1 from '../images/tips/battery.jpg'
import Right from "../images/icon-right.svg"

const Promociones = ({ promos, tips, uuid, customerId }) => (
    <section id="promociones" className="categories promos">
      <div className="top">
        <div className="container">
          <h2 className="title">Promociones</h2>
        </div>
      </div>
    
      <div className="content">
        <div className="container">
          <div className="modPromos">
            { promos.map( (promo, index) =>{
                if (promo.id!==""){
                return <div key={"promo-"+index} className="item">
                <span className="ribbon">{promo.ribbon}</span>
                <span className="ima f16x9">
                  {/*<img src={Promo1} alt="" className="inline-photo show-on-scroll" style={{ display: "none" }} />*/}
                  <GatsbyImage
                    image={promo.image.childImageSharp.gatsbyImageData}
                    alt={promo.name}                    
                    className="inline-photo show-on-scroll"
                  />    
                </span>
                <div className="caption">
                  <p className="category">Promoción</p>
                  <p className="title">{promo.name}</p>
                  <p className="desc black">{promo.description} </p>
                  <div className="buttons">
                    <a href={promo.id==="0"?"https://www.boschcarservice.com/es/es/pide-cita#/booking/classic/landing?customerId="+customerId:"https://www.boschcarservice.com/es/es/pide-cita#/booking/service/"+promo.id+"/workshop/"+uuid+"/calculation"} 
                    target="_blank" rel="noreferrer" className="btn btnType2 small btnContactUs_">Pide cita</a>
                  </div>
                </div>
              </div> }else return null
              })
            }
            {
              tips.map( (tip, index) =>{
                if (tip.enabled === true){
                return <div key={"tip-"+index} className="item">
                  <span className="ima f16x9">
                    {/*<img src={Tip1} alt="" className="inline-photo show-on-scroll" style={{ display: "none" }} />*/}
                    <GatsbyImage
                      image={tip.image.childImageSharp.gatsbyImageData}
                      alt={tip.name}                    
                      className="inline-photo show-on-scroll"
                    />  
                  </span>
                  <div className="caption">
                    <p className="category">CONSEJOS ÚTILES</p>
                    <p className="title">{tip.name}</p>
                    <p className="desc black">{tip.description} </p>
                    <a href={tip.url} target="_blank" rel="noreferrer" className="link">
                      <span>Conoce más</span>
                      <img src={Right} alt="" />
                    </a>
                  </div>
                </div>} 
                else return null
              })
            }
            
          </div>
        </div>
      </div>
    </section>
    )
export default Promociones