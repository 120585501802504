import React from "react"
import Fb from '../images/icon-facebook.png'
import Tw from '../images/icon-twitter.png'
import Ig from '../images/icon-instagram.png'
import Ln from '../images/icon-linkedin.png'
import Yt from '../images/icon-youtube.png'
import Logo from '../images/logo.png'

const Footer = ({ taller }) => (
    <footer className="footer">
        <div className="container" >
            <div className="logo">
                <img src={Logo} alt="Bosch" />
            </div>
            <ul className="listSocial">
                {taller.footer.rrss.map( (rs, index) =>{
                    var img = Fb;
                    if (rs.name === "facebook") img = Fb;                    
                    if (rs.name==="twitter") img = Tw;
                    if (rs.name==="instagram") img = Ig;
                    if (rs.name==="linkedin") img =Ln;
                    if (rs.name==="youtube") img =Yt;
                    return <li key={"rrss-"+index}><a href={rs.url} target="_blank" rel="noreferrer"><img src={img} alt={rs.name} /></a></li>
                })}                
            </ul>
            <ul className="listPolicy">
                <li>
                <a href="/" className="link linkModal" data-modal data-rel="#privacy-modal">POLÍTICA DE PRIVACIDAD</a>
                </li>
            </ul>
        </div>
    </footer>
)
export default Footer