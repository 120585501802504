import React from "react"
import Map from '../images/map.jpg'

const Network = ({ taller }) => (
    <section id="network" className="network">
        <div className="top">
            <div className="container">
                <h2 className="title">Dónde encontrarnos</h2>
            </div>
        </div>
        <div className="content">
            <div className="container" style={{width: "100%"}}>
                <div className="flex">
                    <div className="map" id="map">
                        <img src={Map} alt="" />
                    </div>
                    <div className="cont">
                        <h2 className="title" dangerouslySetInnerHTML={{ __html: taller.title }} />
                        <p className="desc">{taller.contact.address}<br/>
                            {taller.contact.province}<br/>
                            {taller.contact.phones.map( (phone, index) =>{ 
                                if (!phone.whatsapp) return <a key={"phone-"+index} href={"tel:"+phone.phone}>{phone.phone}</a>
                                else return <a key={"phone-"+index} href={"whatsapp://send?phone=34"+phone.phone}>{phone.phone}</a>
                                })
                            }
                            <br/>
                            Pide cita - <a target="_blank" rel="noreferrer" href="https://www.boschcarservice.com/es/es/pide-cita">MiBoschCarService</a><br/>
                            {taller.contact.hours}
                        </p>
                    </div>
                </div>
            </div>
      </div>
    </section>
    )
export default Network