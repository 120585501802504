import React from "react"

const Empresa = ({ taller }) => (
    <section id="empresa" className={ "discover discover" + taller.headerImage}>
      <div className="cooling">
        <p>Red de talleres Bosch Car Service</p>
      </div>
      <div className="content">
        <div className="container">
          <div className="cont">
            <h1 className="title">Tu automóvil en manos expertas</h1>
            <p className="desc">Bosch Car Service tu taller de confianza para la reparación y mantenimiento de vehículos multimarca.</p>
          </div>
        </div>
      </div>
    </section>
)
export default Empresa