import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import "../sass/index.scss"

import Left from "../images/icon-left.png"
import Right from "../images/icon-right.svg"
import Header from '../components/Header.js'
import Empresa from '../components/Empresa.js'
import Carousel from '../components/Carousel.js'
import Servicios from '../components/Servicios.js'
import Promociones from '../components/Promociones.js'
import Contacto from '../components/Contacto.js'
import Network from '../components/Network.js'
import Footer from '../components/Footer.js'
import Cookies from '../components/Cookies.js'
import Modal from '../components/Modal.js'
import ModalPolicy from '../components/ModalPolicy.js'
import ModalData from '../components/ModalData.js'
import ModalCookies from '../components/ModalCookies.js'

// markup
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Taller = edges[0].node.frontmatter;  
  //const Talleres = null// edges.map(edge =>  <p> {edge.node.frontmatter.title} </p>)
  return <>
 
  <Header taller={Taller} />
   
  <main className="main">
    <Empresa taller={Taller} />    
    <Carousel taller={Taller} />    
    <Servicios services={Taller.services} uuid={Taller.uuid} customerId={Taller.customerId}/>
    <Promociones promos={Taller.promotions} tips={Taller.tips} uuid={Taller.uuid} customerId={Taller.customerId}/>
    <Contacto taller={Taller} />
    <Network taller={Taller} />
    <Footer taller={Taller}/>
    <Cookies footer={Taller.footer} />
    <Modal taller={Taller} id="modal" />
    <ModalPolicy taller={Taller} id="privacy-modal" />
    <ModalData taller={Taller} id="data-modal" />
    <ModalCookies taller={Taller} id="cookies-modal" />

  </main>

  <Helmet>
    <meta charSet="utf-8" />
    <title>{Taller.title.replace(/(<([^>]+)>)/gi, "")}</title>
    <link rel="canonical" href={Taller.url} />
    <meta name="description" content={Taller.about.replace(/(<([^>]+)>)/gi, "")}/>
    <meta name="og:title" content ={Taller.title} />
    <meta name="og:description" content ={Taller.about.replace(/(<([^>]+)>)/gi, "")} />
    <meta name="og:image" content ={Taller.url + getSrc(Taller.image)} />
    <meta name="og:url" content ={Taller.url} />    
  </Helmet>

  <div style={{display: "none"}}>
    <img alt="" id="left" src={Left}/>
    <img alt="" id="right" src={Right}/>
    <span id="lat">{Taller.contact.position.lat}</span>
    <span id="long">{Taller.contact.position.long}</span>
  </div>
  </>
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {            
            title
            url
            customerId
            uuid
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED 
                  transformOptions: { fit: COVER }
                  width: 1024
                  height: 639
                  quality: 100
                  placeholder: BLURRED
                )
              }
            }
            about
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 215
                    width: 477                    
                    quality: 100
                    placeholder: BLURRED
                    )
                }
              }
            }
            services {
              id
              name
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 466
                    width: 621
                    quality: 100
                    placeholder: BLURRED
                    )
                }
              }
            }
            promotions{
              id
              ribbon
              name
              description
              image  {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH                    
                    quality: 100
                    placeholder: BLURRED
                    )
                }
              }
            }
            tips{
              name
              description
              url
              image  {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH                    
                    quality: 100
                    placeholder: BLURRED
                    )
                }
              }
              enabled
            }
            contact{
              privacyPolicy
              dataPolicy
              name
              address
              province
              phones{
                phone
                whatsapp
              }
              position{
                lat,
                long
              }
              hours
              email
            }
            footer{
              rrss{
                name
                url
              }
              popup
              cookiePolicy
            }
            headerImage
          }
        }
      }
    }
  }
`