import React from "react"

const Cookies = ({ footer }) => (
    <div className="modCookies" style={{display: "none"}}>
        <div className="container">
            <div className="content">
                <div className="left">
                    <div id="step1" className="step">
                        <p className="tit">Política de cookies</p>
                        <p className="desc">{footer.popup} 
                            <a href="/" className="link linkModal" data-modal data-rel="#cookies-modal">Política de cookies</a>.
                        </p>
                    </div>
                    <div id="step2" className="step">
                        <p className="tit">Borrar, habilitar y gestionar cookies</p>
                        <p className="desc">Puedes permitir, bloquear o borrar cookies y configurar tus preferencias dsde tu navegador.<br/><br/>
                            En los siguientes enlaces encontrarás la información para configurar o deshabilitar cookies en cada navegador:<br/><br/>
                            <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noreferrer">Google Chrome</a>
                            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noreferrer">Mozilla Firefox</a>
                            <a href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">Internet Explorer</a>
                            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Safari</a>
                            <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noreferrer">Safari IOS (iPhone & iPad)</a>
                            <a href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=637456992951048550-1771999392&rd=1" target="_blank" rel="noreferrer">Chrome Android</a>
                        </p>
                    </div>
                </div>
                <div className="right">
                    <button type="button" id="btnChangeConfigCookies" className="btn btnType3 btnChange">Configurar Cookies</button>
                    <button type="button" id="btnAcceptCookies" className="btn btnType1 btnAccept">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
)
export default Cookies