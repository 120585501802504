import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Servicios = ({ services, uuid, customerId }) => (
    <section id="servicios" className="categories type3">
      <div className="top">
        <div className="container">
          <h2 className="title">Servicios</h2>
        </div>
      </div>

      <div className="content">
        <div id="owlCarouselCategories" className="owl-carousel owl-theme owl-carousel-categories">
          { services.map( (service, index)=>{              
              return <div key={"service-"+index} className="item modCategory">
              <div className="top">
                <span className="ima f12x9 vCenter">                  
                  <GatsbyImage
                    image={service.image.childImageSharp.gatsbyImageData}
                    alt={service.name}    
                    className="inline-photo show-on-scroll"                
                  />                 
                </span>
                <span className="line"></span>
              </div>
              <div className="cont">
                <div className="box">
                  <p className="title">{service.name}</p>
                </div>
                <div className="desc"  dangerouslySetInnerHTML={{ __html: service.description }} />
                <div className="buttons">
                  <a href={service.id==="0"?"https://www.boschcarservice.com/es/es/pide-cita#/booking/classic/landing?customerId="+customerId:"https://www.boschcarservice.com/es/es/pide-cita#/booking/service/"+service.id+"/workshop/"+uuid+"/calculation"} 
                  target="_blank" rel="noreferrer" className="btn btnType2 small btnContactUs_">Pide cita</a>
                </div>
              </div>
            </div>
            })
          }          
          
        </div>
      </div>
    </section>
)
export default Servicios
